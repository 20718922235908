import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import config from "../config";

// Initialize Firebase
const app = initializeApp(config);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const getProvider = () => {
  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
  provider.addScope('openid');
  provider.setCustomParameters({
    'login_hint': 'user@example.com'
  });
  return provider;
} 
