import { useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../provider/firebase'

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const register = async () => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password)
      return result;
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div>
      <input
        type='text'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      </div>
      <button onClick={register}>Register</button>
    </div>
  )
}
export default Register
