import { useState } from 'react'
import {
  login,
} from '../auth'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  return (
    <div style={{ margin: '1rem 1rem' }}>
      <div>
        <input
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button onClick={(async() => await login(email, password))}>Login</button>
    </div>
  )
}
