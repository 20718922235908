import axios from 'axios'
import {
  browserSessionPersistence,
  GoogleAuthProvider,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import config from "../config";
import { auth, getProvider } from '../provider/firebase';

axios.defaults.baseURL = config.url; //

export const getCookieMap = (): Record<string, string> => {
  const cookies = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .reduce(
      (map: Record<string, string>, cookie: string): Record<string, string> => {
        const tempMap = { ...map }
        const [key, value] = cookie.split('=')
        tempMap[key] = value
        return tempMap
      },
      {}
    )
  return cookies
}

export const signInWithToken = async (customToken: string) => {
  await setPersistence(auth, browserSessionPersistence)
  return signInWithCustomToken(auth, customToken);
}

export const getCrossSiteScriptTokenCookie = async (firebaseAuthIdToken: string): Promise<string> => {
  await axios.post(`/api/auth/signIn`,
    { idToken: firebaseAuthIdToken },
    { withCredentials: true }
  )
  return getCookieMap().csst ?? "";
}

export const getCustomToken = async (csstCookie: string) => {
  const checkAuthResult = await axios.get(`/api/auth/checkAuth`, {
    headers: {
      Authorization: `Bearer ${csstCookie}`,
    },
    withCredentials: true,
  })
  return checkAuthResult.data.token;
}

export const login = async (email: string, password: string) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password)
    if (!user) {
      return
    }
    const { token } = await user.getIdTokenResult()
    if (!token) {
      return
    }
    const cssToken = await getCrossSiteScriptTokenCookie(token)
    const customToken = await getCustomToken(cssToken)
    const signInResult = await signInWithToken(customToken)
    return signInResult
  } catch (error) {
    console.log(error)
  }
}

export const logout = async () => {
  const result = await axios.post(
    `/api/auth/signOut`,
    {},
    { withCredentials: true }
  )
  if (result.status === 200) {
    await signOut(auth)
  }
}

export const loginWithGoogle = async () => {
  try {
    const provider = getProvider();
    const result = await signInWithPopup(auth, provider)
    // const credential = GoogleAuthProvider.credentialFromResult(result)
    const { token } = await result.user.getIdTokenResult()
    const cssToken = await getCrossSiteScriptTokenCookie(token);
    const customToken = await getCustomToken(cssToken);
    const signInResult = await signInWithToken(customToken);
    return signInResult;
  } catch (error: any) {
    const errorCode = error.code
    const errorMessage = error.message
    // The email of the user's account used.
    const email = error.email
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error)
    console.log(errorCode, errorMessage, email, credential)
  }
}