import './App.css'
import { Login } from './components/Login'
import { Logout } from './components/Logout'
import { LoginGoogle } from './components/LoginGoogle'
import Register from './components/Register'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Register />
          <Login />
          <LoginGoogle />
          <Logout/>
        </div>
        <p>
          { JSON.stringify(document.cookie) }
        </p>
      </header>
    </div>
  )
}

export default App
